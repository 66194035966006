import React from "react";
import styled from "styled-components";

const HeroContainer = styled.div`
  width: 100vw;
  background-color: #000;
`;

const HeroImage = styled.img`
  height: calc(50vh - 70px);
  width: 100vw;
  object-fit: cover;
  opacity: 0.5;
  @media (max-width: 600px) {
    height: calc(70vh - 56px);
  }
`;

const Herotext = styled.div`
  margin: 0;
  position: absolute;
  top: 25%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: #fff;
`;
const HeroTitle = styled.div`
  margin: auto;
  font-size: 40px;
  font-weight: 800;
  @media (max-width: 600px) {
    font-size: 24px;
    width: 80vw;
  }
`;
// const HeroSubTitle = styled.div`
//   font-size: 24px;
//   font-weight: normal;
//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// `;

const Banner = () => {
  return (
    <HeroContainer>
      <HeroImage src="/static/images/AWS-1-banner.jpg" />
      <Herotext>
        <HeroTitle>
          {" "}
          AWS Certified Solutions Architect – Associate 
        </HeroTitle>
      </Herotext>
    </HeroContainer>
  );
};

export default Banner;
