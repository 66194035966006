import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'AWS Certified Solution Architect (Asscociate)',
    // path: '/Quadratics',
    path: '/',
    icon: <FaIcons.FaCode />,

    subNav: [
            {
              title: 'AWS Fundamentals',
              path: '/Fundamentals',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Security',
              path: '/Security',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Virtual Private Cloud (VPC)',
              path: '/VPC',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'EC2 and EBS',
              path: '/EC2',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Loadbalancing/High Avaliability',
              path: '/Loadbalancing',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Route 53',
              path: '/Route53',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Databases',
              path: '/Databases',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Simple Storage Solution (S3)',
              path: '/S3',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Monitoring',
              path: '/Monitoring',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Automation',
              path: '/Automation',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Caching',
              path: '/Caching',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Governance',
              path: '/Governance',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Migration',
              path: '/Migration',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Big Data',
              path: '/BigData',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Serverless',
              path: '/Serverless',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            },
            {
              title: 'Extra Stuff',
              path: '/ExtraStuff',
              icon: <FaIcons.FaCode />,
              cName: 'sub-nav'
            }
          ]
  },
//   {
//     title: 'Exponents and Nature of Roots',
//     path: '/#',
//     icon: <FaIcons.FaAtom />,
//     iconClosed: <RiIcons.RiArrowDownSFill />,
//     iconOpened: <RiIcons.RiArrowUpSFill />,
// /*
//     subNav: [
//       {
//         title: 'Reports',
//         path: '/reports/reports1',
//         icon: <IoIcons.IoIosPaper />,
//         cName: 'sub-nav'
//       }
//     ]*/
//   },
  
  {
    title: 'Coming Soon - AWS Certified Developer (Associate)',
    path: '/#',
    icon: <FaIcons.FaCode />
  },
  
  {
    title: 'Coming Soon - AWS Certified Sysops Administrator',
    path: '/#',
    icon: <FaIcons.FaCode />
  },
  
  {
    title: 'Coming Soon - Python for DevOps',
    path: '/#',
    icon: <FaIcons.FaCode />
  },

  // {
  //   title: 'About',
  //   path: '/#',
  //   icon: <FaIcons.FaEnvelopeOpenText />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },

  {
    title: 'Support',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />
  }
];
