import React from 'react';
import './App.css';
import GridView from '../GridView';

// Insert Location 7
import { withAuthenticator } from 'aws-amplify-react';
import Sidebar from '../Sidebar/Sidebar';



function App() {
  return (
    <div className="App">
      <Sidebar/>
      <header className="App-header">
        <GridView />
      </header>
    </div>

  );
}

// Insert Location 8
export default withAuthenticator(App, true);
