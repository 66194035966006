/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import "./index.css";
const videojs = window.videojs;

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    this.player = videojs("vid1");
    this.player.src(this.props.sources);
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  componentDidUpdate() {
    if (this.props.sources !== this.player.src()) {
      this.player.src(this.props.sources);
      this.player.vastClient({
        adTagUrl:
          "https://www.videosprofitnetwork.com/watch.xml?key=77710b06573d72ea943782c66ea22c56",
        adsCancelTimeout: 100000,
        playAdAlways: true,
        vastLoadTimeout: 100000,
        adsEnabled: true,
        preferredTech: "html5",
        vpaidFlashLoaderPath:
          "//github.com/MailOnline/videojs-vast-vpaid/blob/RELEASE/bin/VPAIDFlash.swf?raw=true",
      });
    }
  }

  render() {
    return (
      <div id="parent-video">
        <div data-vjs-player>
          <video
            id="vid1"
            controls
            data-setup='{
              "plugins": {
                      "vastClient": {
                      "adTagUrl": "https://www.videosprofitnetwork.com/watch.xml?key=77710b06573d72ea943782c66ea22c56",
                      "adsCancelTimeout": 100000,
                      "playAdAlways": true,
                      "vastLoadTimeout": 100000,
                      "adsEnabled": true,
                      "preferredTech":"html5",
                      "vpaidFlashLoaderPath": "//github.com/MailOnline/videojs-vast-vpaid/blob/RELEASE/bin/VPAIDFlash.swf?raw=true"
                  }
              }}'
            className="video-js vjs-16-9"
          ></video>
        </div>
      </div>
    );
  }
}
