import React from "react";
import styled from "styled-components";
import Feature from "./Feature";
// import Trending from "./Trending";
import CustomBanner from "./CustomBanner";
import TopCategory from "./TopCategory";
const HeroContainer = styled.div`
  width: 100vw;
  background-color: #000;
`;

const HeroImage = styled.img`
  height: calc(70vh - 70px);
  width: 100vw;
  object-fit: cover;
  opacity: 0.7;
  @media(max-width: 600px) {
    height: calc(70vh - 56px);
  }
`;

const Herotext = styled.div`
  margin: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: #fff;
`;
const HeroTitle = styled.div`
  margin: auto;
  font-size: 40px;
  font-weight: 800;
  @media(max-width: 600px) {
    font-size: 24px;
    width: 80vw;
  }
`;
const HeroSubTitle = styled.div`
  font-size: 24px;
  font-weight: normal;
  @media(max-width: 600px) {
    font-size: 16px;
  }
`;

function Home() {
  return (
    <>
      <HeroContainer>
        <HeroImage src="/static/images/student-banner-1.jpg" />
        <Herotext>
          <HeroTitle>
            {" "}
            BOOST YOUR AWS CLOUD CAREER TODAY
            <HeroSubTitle>
              {" "}
              IT'S 100% FREE WITH CLIXSTREAM
            </HeroSubTitle>
          </HeroTitle>
        </Herotext>
      </HeroContainer>

      <Feature />

      <CustomBanner />

      <TopCategory />
    </>
  );
}

export default Home;
