import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "reactstrap";
import { Box, Typography } from "@material-ui/core";
import awsvideo from "../../aws-video-exports";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VideoPlayer from ".././VideoPlayer";
import BannerSide from "./BannerSide";
import "./aws.css";
const OurCoursesContainer = styled(Container)`
  padding: 50px 20px !important;
`;

// const StyledTitle = styled.div`
//   font-weight: 700;
//   font-size: 24px;
//   color: #000;
//   margin-left: 20px;
// `;

const StyledDescription = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #000;
  margin-bottom: 50px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const OurCourse = () => {
  const [items, setItems] = React.useState([]);
  const [item, setItem] = React.useState(undefined);
  const [video, setVideo] = React.useState(undefined);
  const [showVideo, setShowVideo] = React.useState(false);
  /* eslint-disable-next-line */
  const [selectedTab, setSelectedTab] = React.useState(0);

  const playVideo = (item) => {
    setVideo(
      `https://${awsvideo.awsOutputVideo}/${item.video.id}/${item.video.id}.m3u8`
    );
    setItem(item);
  };
  React.useEffect(() => {
    API.graphql(
      // graphqlOperation(queries.sortedresults, { course: "aws_security" })
      {
        query: queries.sortedresults,
        variables: {course: "aws_loadbalancing"},
        authMode: 'API_KEY'
      }
    ).then((assets) => {
      setItems(assets.data.sortedresults.items);
      const item = assets.data.sortedresults.items[0];
      setVideo(
        `https://${awsvideo.awsOutputVideo}/${item.video.id}/${item.video.id}.m3u8`
      );
      setItem(item);
    }).catch(e =>{console.log(e)});
  }, []);

  return (
    <OurCoursesContainer fluid>
      <Row>
        <Col></Col>
      </Row>
      <Row></Row>
      <Row>
        <Col md={12}>
          <Col>
            <Col sm={12} md={8} >
              <StyledDescription>In this module you’ll learn about the principles of High Availability, Load balancers and Disaster recovery. 
                The skills you learn in this module will enable you to design and build scalable, disaster tolerant applications in the AWS cloud. 
                This is an essential module as downtime in production systems can have serious consequences.
              </StyledDescription>
            </Col>
          </Col>
          <Col>
            <Row>
              <Col sm={12} md={8} className="videoContainer">
                {video && (
                  <VideoPlayer controls sources={video} bigPlayButton={false} />
                )}
              </Col>
              <Col sm={3} md={3}>
                <TabPanel value={selectedTab} index={0}>
                  {items.map((value) => {
                    return (
                      <BannerSide click={playVideo} data={value} item={item} key={Math.random()} />
                      );
                    })}
                </TabPanel>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
      <Modal
        id="popup"
        style={{ maxWidth: 755 }}
        isOpen={showVideo}
        toggle={() => setShowVideo(!showVideo)}
      >
        <ModalHeader toggle={() => setShowVideo(!showVideo)}>
          {item?.title}
        </ModalHeader>
        <ModalBody>
          {item?.description}
          <VideoPlayer
            controls
            sources={video}
            width={720}
            height={420}
            bigPlayButton={false}
            autoplay
          />
        </ModalBody>
      </Modal>
    </OurCoursesContainer>
  );
};

export default OurCourse;
