import React from "react";
import { useHistory } from "react-router";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";

const TitleCol = styled(Col)`
  text-align: center;
  font-weight: 700;
  font-size: 28px;
  color: #000;
`;
const ImageContainer = styled.div`
  background-color: #000;
`;
const CourseImage = styled.img`
  opacity: 0.7;
`;

const CourseName = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  font-size: 100%;
  font-weight: 800;
  color: #fff;
  > span {
    font-size: 16px;
    font-weight: normal;
  }
`;
const CourseTile = styled(Col)`
  padding: 15px;
  cursor: pointer;
  @media (max-width: 600px) {
    margin: 0px 30px;
  }
`;

const TopCategoryContainer = styled(Container)`
  padding: 50px 0px !important;
`;

export default function TopCategory() {
  let history = useHistory();
  function redirect(path) {
    history.push(path);
  }
  return (
    <TopCategoryContainer>
      <Row>
        <TitleCol>Essential Topics</TitleCol>
      </Row>
      <br />
      <br />
      <Row>
            <CourseTile
              // key={index}
              sm={12}
              md={3}
              onClick={() => redirect("/EC2")}
            >
              <ImageContainer>
                <CourseImage src="static/images/Blank_tile.jpg" width="100%" />
                <CourseName>
                Amazon EC2 <br />
                  {/* <span> 16 Modules </span> */}
                </CourseName>
              </ImageContainer>
            </CourseTile>
            <CourseTile
              // key={index}
              sm={12}
              md={3}
              onClick={() => redirect("/S3")}
            >
              <ImageContainer>
                <CourseImage src="static/images/Blank_tile.jpg" width="100%" />
                <CourseName>
                Amazon S3<br />
                  {/* <span> Coming Soon </span> */}
                </CourseName>
              </ImageContainer>
            </CourseTile>
            <CourseTile
              // key={index}
              sm={12}
              md={3}
              onClick={() => redirect("/Security")}
            >
              <ImageContainer>
                <CourseImage src="static/images/Blank_tile.jpg" width="100%" />
                <CourseName>
                Amazon IAM<br />
                  {/* <span> Coming Soon </span> */}
                </CourseName>
              </ImageContainer>
            </CourseTile>
            <CourseTile
              // key={index}
              sm={12}
              md={3}
              onClick={() => redirect("/VPC")}
            >
              <ImageContainer>
                <CourseImage src="static/images/Blank_tile.jpg" width="100%" />
                <CourseName>
                Amazon VPC<br />
                  {/* <span> Coming Soon </span> */}
                </CourseName>
              </ImageContainer>
            </CourseTile>                                    
      </Row>
    </TopCategoryContainer>
  );
}
