import React from "react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";

const HeroContainer = styled.div`
  width: 100vw;
  background-color: #1B212D;
  padding: 40px;
`;

// const HeroImage = styled.img`
//   // height: calc(50vh - 70px);
//   width: 100%;
//   object-fit: fit;
//   opacity: 1;
//   @media (max-width: 600px) {
//     // height: calc(70vh - 56px);
//   }
// `;

const Title = styled.div`
  margin: auto;
  padding-bottom: 40px;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  color: #fff;

  @media(max-width: 600px) {
    font-size: 24px;
    width: 80vw;
  }
`;

const ImageContainer = styled.div`
  // background-color: #000;
  // padding: 50px 20px 50px 20px;
  margin: auto;
  text-align: center;
`;

const CourseImage = styled.img`
  opacity: 1;
  margin: auto;
  width: 60%;
`;

const Heading = styled.div`
  position: relative;
  color: #fff;
  font-weight: 800;
  font-size: 20px;
  padding-top: 30px;
`;

const BodyContainer = styled.div`
  display: bloc;
  width: 100%;
`;

const BodyCopy = styled.div`
  text-align: left;
  font-size: 30px;
  color: #fff;
`;

const BodyIcon = styled.img`
  opacity: 1;
  width: 30px;
  display: inline;
  margin-right: 10px;
`;

const BodyParagraph = styled.div`
  text-align: left;
  font-size: 18px;
  color: #fff;
  display: inline;
`;

const BodyListBlock = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  display: inline;
`;

const BodyListTitle = styled.p`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  display: inline;
`;

const BodyButton = styled.button`
  color: #0145AC;
  margin: 25px 10px 0px 0px;
  background-color: #fff;
  padding: 10px 25px;
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  border-radius: 5px;
`;




function CustomBanner() {
    return (
      <div>
        <HeroContainer>
              <Row>
                <Title>
                    {" "}
                    AWS Certified Solutions Architect<br />(Associate)
                </Title>
              </Row>
              <Row>
                  <Col
                    sm={12}
                    md={6}
                  >
                    <ImageContainer>
                      <CourseImage src="static/images/student-2.jpg" width="100%" />
                    </ImageContainer>
                  </Col>  
                  <Col
                    sm={12}
                    md={6}
                  >
                    <Row>
                      <Heading>
                          {" "}
                          COURSE SUMMARY
                      </Heading>
                      <BodyContainer>
                        <BodyCopy>
                          {/* <BodyIcon src="./logo192.png" width="100%" /> */}
                          <BodyParagraph>
                            This course is designed to prepare you to write the AWS Solutions Architect - Associate course (SAA-C02 ). 
                            The content is formatted in short video courses (approx 20mins), including hands-on demos. 
                            No prior AWS knowledge or programming knowledge is required to take this course. 
                            This course will provide you with all the basis you need to get you cloud career started, including the most used AWS resources such as Amazon EC2, Amazon S3, Amazon VPC etcetera.
                            </BodyParagraph>
                        </BodyCopy>
                      </BodyContainer>
                    </Row>
                    <Row>
                      <Heading>
                          {" "}
                          INCLUDED RESOURCES
                      </Heading>
                      <BodyContainer>
                        <BodyCopy>
                          <BodyListBlock>+11hrs </BodyListBlock>
                          <BodyListTitle> Video Content</BodyListTitle>
                        </BodyCopy>
                        <BodyCopy>
                          <BodyListBlock>+40 </BodyListBlock>
                          <BodyListTitle> AWS services covered</BodyListTitle>
                        </BodyCopy>                        
                        <BodyCopy>
                          <BodyListBlock>Over 30 </BodyListBlock>
                          <BodyListTitle> Hands on Labs</BodyListTitle>
                        </BodyCopy>
                        <BodyButton><a href="./fundamentals" >Get started here</a></BodyButton>
                        {/* <BodyButton>Watch Teaser</BodyButton> */}
                      </BodyContainer>
                    </Row>
                  </Col>
              </Row>
              <Row>
                
              </Row>
        </HeroContainer>
      </div>
    )
}

export default CustomBanner;