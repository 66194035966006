import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: '#FFFFFF',
  }
}));

const SubMenu = ({ item, key, handleCloseEve }) => {
  let history = useHistory();

  function redirect(path) {
    history.push(path);
  }
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  if (item.subNav) {
    return <> 
    <ListItem key={item.key} button >
      <ListItemIcon className={classes.icon} to={item.path} component={Link} onClick={handleCloseEve}> 
        {item.icon}
      </ListItemIcon>
      <ListItemText primary={item.title}  onClick={() => {handleCloseEve(); redirect(item.path)}}/>
      {open ? <ExpandLess onClick={handleClick}/> : <ExpandMore onClick={handleClick}/>}
    </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subNav &&
            item.subNav.map((item, index) => {
              return (
                <ListItem key={index} to={item.path} button className={classes.nested} component={Link} onClick={() => {handleCloseEve(); redirect(item.path)}}>
                  <ListItemIcon className={classes.icon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              );
            })}

        </List>
      </Collapse> </>
  } else {
    return (
      <ListItem key={item.key} to={item.path} button component={Link} onClick={handleCloseEve}>
        <ListItemIcon className={classes.icon}>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItem>
    )
  }
}

export default SubMenu;
