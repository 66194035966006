import React from "react";
import { withAuthenticator } from "aws-amplify-react";
import Banner from "./Banner";
import OurCourse from "./OurCourse";


function EC2() {
  return (
    <div className="Maths">
      {/* <Banner /> */}
      <OurCourse />
    </div>
  );
}

// Insert Location 8
export default (EC2);