import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "reactstrap";
// import TwitterIcon from '@material-ui/icons/Twitter';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import YouTubeIcon from '@material-ui/icons/YouTube';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
const FooterContainer = styled(Container)`
  background-color: #292f39;
  padding: 50px 0px;
  @media(max-width: 600px) {
    .col-md-3 {
      margin-bottom: 40px;
    }
  }
`;

const FooterTitle = styled(Col)`
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  padding: 0px;
`;

const NewsLetterTextfield = styled.input`
  background-color: #fff !important;
  width: 80%;
  padding: 10px;
  outline: none !important;
  border-radius: 2px;
  border: none;
`;

const FooterContent = styled.div`
  text-align: center;
  > div {
      margin: 5px 0px;
  }
  > div.desc {
    margin: 10px 0px;
  }
  > input {
    width: 60%;
  }
`;

const FooterLogo = styled(Col)`
  text-align: right;
`

const Logo = styled.img`
  height: 40px;
  // margin: auto;
`;

const SubscribeButton = styled.div`
  height: 40px;
  width: 20%;
  background-color: #1eaae2;
`;

const SubscriptionContainer = styled.div`
  background-color: #1eaae2;
  display: flex;
  width: 65%;
  svg {
    color: #fff !important;
    margin: auto;
    display: grid;
    margin-top: 9px;
  }
`;
// const SocialMediaIcons = styled.img`
//   border: 1px solid #fff;
//   padding: 5px;
//   margin: 0px 5px;
//   border-radius: 50%;
// `
// const Twitter = styled(TwitterIcon)`
//   border: 1px solid #fff;
//   padding: 5px;
//   color: #fff !important;
//   margin: 0px 5px;
//   font-size: 30px !important;
//   border-radius: 50%;
// `
// const Instagram = styled(InstagramIcon)`
//   border: 1px solid #fff;
//   padding: 5px;
//   color: #fff !important;
//   margin: 0px 5px;
//   font-size: 30px !important;
//   border-radius: 50%;
// `
// const YouTube = styled(YouTubeIcon)`
//   border: 1px solid #fff;
//   padding: 5px;
//   color: #fff !important;
//   font-size: 30px !important;
//   margin: 0px 5px;
//   border-radius: 50%;
// `

function Footer() {
  // const bottomLinks = [
  //   "About Us",
  //   "Team",
  //   "Our News",
  //   "Courses",
  //   "Contact us",
  //   "Log in",
  // ];
  return (
    <>
      <FooterContainer fluid>
        <Container>
          <Row>
            <Col md={4}>
              <FooterTitle>Get Newsletter</FooterTitle>
                {/* <div className="desc">
                  Be amoung the first ones to receive timely program and event
                  info, career tips, industry trends and more
                </div>
                <br /> */}
                <SubscriptionContainer>
                  <NewsLetterTextfield placeholder="your email address" /> <SubscribeButton><ArrowForwardIcon/></SubscribeButton>
                </SubscriptionContainer>
            </Col>
            {/* <Col md={3}>
              <FooterTitle>Follow Us</FooterTitle>
              <FooterContent>
                <br/>
                <SocialMediaIcons src="static/icons/facebook.svg" height="30"/>
                <Twitter />
                <Instagram />
                <YouTube />
              </FooterContent>
            </Col> */}
            {/* <Col md={3}>
              <FooterTitle>Links</FooterTitle>
              <FooterContent>
                {bottomLinks.map((value) => {
                  return <div>{value}</div>;
                })}
              </FooterContent>
            </Col> */}
            <Col md={4}>
              <FooterContent>
                <div>
                  <strong>Address</strong>
                </div>
                <div>180 Forest Drive Ext, Pinelands
                  <br />
                  Cape Town, 7405
                  <br />
                <a href="mailto:info@clixstream.io" alt="email admin">Email Admin</a>
                </div>
              </FooterContent>
              </Col>
            
              <Col md={4}>              
              <FooterLogo>
                <Logo src="static/images/logo.png"/> <br/>
                <div>Copyright 2021 Clixstream
                  <br />
                  All Right Reserved</div>
                  <a href="./privacy" alt="View the Clixstream Privacy Policy">Privacy Policy</a>
              </FooterLogo>
            </Col>
          </Row>
        </Container>
      </FooterContainer>
    </>
  );
}

export default Footer;
