import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
const Icon = styled.img`
  height: 45px;
  margin: 0px 20px;
`;

const FeatureRow = styled(Row)`
  padding: 50px 0px;
`;

const FeatureCol = styled(Col)`
  display: flex;
  @media (max-width: 600px) {
    margin-bottom: 50px;
  }
`;
const Description = styled.div``;

const DescTitle = styled.div`
  margin: 0px 0px 10px 0px;
  font-size: 20px
  color: #000;
  font-weight: 500;
`;

const DescSubTitle = styled.div`
  font-size: 14px;
`;
export default function Feature() {
  return (
    <Container>
      <FeatureRow>
        <FeatureCol sm={12} md={4}>
          <Icon src="./static/images/icon-1.png"></Icon>
          <Description>
            <DescTitle>AWS Certified Solutions Architect - Associate</DescTitle>
            <DescSubTitle>
            By far the most popular cloud certification. 
            It will validate your ability to design and implement cloud based solutions on the AWS platform
            </DescSubTitle>
          </Description>
        </FeatureCol>
        <FeatureCol sm={12} md={4}>
          <Icon src="./static/images/icon-1.png"></Icon>
          <Description>
            <DescTitle>AWS Certified Developer - Associate</DescTitle>
            <DescSubTitle>
            Earning this certification demonstrates an ability to write, deploy, debug and maintain cloud-based applications. 
            Recommended for individuals with a software development background.
            </DescSubTitle>
          </Description>
        </FeatureCol>
        <FeatureCol sm={12} md={4}>
          <Icon src="./static/images/icon-1.png"></Icon>
          <Description>
            <DescTitle>AWS Certified SysOps Administrator - Associate </DescTitle>
            <DescSubTitle>
            Aimed at system administrators in cloud-ops roles. 
            This certification will validate your technical skills, experience deploying, managing, and operating scalable and fault-tolerant applications on the AWS platform
            </DescSubTitle>
          </Description>
        </FeatureCol>
      </FeatureRow>
    </Container>
  );
}
