import React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
const CourseTitle = styled(Col)`
  font-size: 18px;
  min-height: 27px;
  font-weight: 500;
  color: #000;
`;
const CourseDescription = styled(Col)`
  font-size: 18px;
  min-height: 76px;
  margin: 0px 15px !important;
  padding-left: 0px !important;
  padding-bottom: 10px !important;
  font-weight: 500;
  color: #000;
`;
const LeactureContainer = styled(Row)`
  padding: 20px 0px !important;
  border-bottom: 1px solid #d2d2d2;
  cursor: pointer;
`;
export default function BannerSide({ click, data, item }) {
  return (
    <LeactureContainer>
      <Col sm={12}>
        <Row style={{ alignItems: "center" }}>
          <input type="radio" checked={item?.id === data?.id}/>
          <CourseTitle onClick={() => click(data)}>{data?.title}</CourseTitle>
          {item?.id === data?.id && <PlayCircleOutlineIcon />}
        </Row>
        {data?.description && (
          <Row>
            <CourseDescription>{data?.description}</CourseDescription>
          </Row>
        )}
      </Col>
    </LeactureContainer>
  );
}
