import React from "react";
import { Auth } from "aws-amplify";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
// import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Sidebar from "../Sidebar/Sidebar";
// import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    backgroundColor: "#1eaae2",
  },
  header: {
    backgroundColor: "#292f39",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  headerButton: {
    backgroundColor: "#fff",
    color: "#1eaae2",
    minWidth: "150px",
    margin: "0px 10px",
    fontWeight: 400,
  },
  sectionDesktop: {
    display: "none",
    alignItems: 'center',
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const Logo = styled.img`
  height: 30px;
  margin: 0px 30px;
`;

// const ActionButtons = styled(Button)`
//   border: none !important;
//   box-shadow: none !important;
//   font-weight: 600 !important;
//   :hover,
//   :active,
//   :focus {
//     background-color: #fff !important;
//   }
// `;

const UserName = styled.div`
  color: #fff;
  margin: 0px 20px;
  font-size: 18px;
  font-weight: 500;
  align-item: center;
`;

export default function Header() {
  const [isSignedIn, setSignedIn] = React.useState(false);
  const [authUser, setAuthUser] = React.useState(null);
  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(AuthUser => {
      if (AuthUser !== null) {
        setSignedIn(true);
        setAuthUser(AuthUser);
      }
    });
  }, []);
  console.log(isSignedIn);
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const signOut = async () => {
    try {
      if (isSignedIn) {
        await Auth.signOut();
        setSignedIn(false);
      }
    } catch (ex) {
      // console.error("dis here: " + ex);
    }
  };


  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <Button
          variant="contained"
          color="primary"
          className={classes.headerButton}
        >
          LOG IN
        </Button>
      </MenuItem> */}
      {authUser && (
        <MenuItem>
          <UserName variant="contained" color="primary">
            Hi, {authUser.username}
          </UserName>
        </MenuItem>
      )}
      <MenuItem>
        <Button
          variant="contained"
          color="primary"
          className={classes.headerButton}
          onClick={signOut}
        >
          {isSignedIn ? "SIGN OUT" : "SIGN IN"}
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Sidebar />
          <Typography className={classes.title} variant="h6" noWrap>
            <Logo src="static/images/logo.png" />
          </Typography>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              inputProps={{
                "aria-label": "search",
                style: {
                  width: "100%",
                  padding: "20px",
                  border: "none",
                  margin: "0",
                  color: "#fff",
                  paddingLeft: "50px",
                },
              }}
            />
          </div> */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <ActionButtons
              variant="contained"
              color="primary"
              className={classes.headerButton}
            >
              LOG IN
            </ActionButtons> */}
                  {authUser && 
          <UserName variant="contained" color="primary">
            Hi, {authUser.username}
          </UserName>
      }
            {/* <ActionButtons
              variant="contained"
              color="primary"
              onClick={signOut}
              className={classes.headerButton}
            >
              {isSignedIn ? "SIGN OUT" : "SIGN IN"}
            </ActionButtons> */}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
