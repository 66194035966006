/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:1cb13444-02f4-4ed8-bd36-03a2168d2d1b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_yMBKZ9rmD",
    "aws_user_pools_web_client_id": "3ooari26i617qi4dclv27ggmq4",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ywulv3cisjdlpgogr3kmhb5tuy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-oowkpbohebe6nfykq3kizoj7ra",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://wxhwvzqzs8.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
