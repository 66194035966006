import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import styledComponents from "styled-components";
const BannerContainer = styledComponents(Row)`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #292f39;
    color: #f8f8f8
    padding: 15px;
    margin: 0;
    font-size: 14px;
    box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.2), 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`;
export default function Banner() {
  const [display, setDisplay] = useState(true);
  const cookieChoice = (result) => {
    localStorage.setItem("cookieChoice", result);
    setDisplay(false)
  }
  return display ? (
    <BannerContainer>
      <Col sm={10}>
        By Visting and Using the website, you agree to the storing of cookies on
        your device to enhance site navigation, analyze site usage, and assist
        in our marketing efforts. For more details, read our Cookie Policy.
      </Col>
      <Col sm={1}>
        <Button
          style={{ backgroundColor: "#1eaae2", float: "right" }}
          onClick={() => cookieChoice(true)}
        >
          {" "}
          Okay{" "}
        </Button>
      </Col>
      <Col sm={1}>
        <Button
          style={{ backgroundColor: "#CDCDCD" }}
          onClick={() => cookieChoice(false)}
        >
          {" "}
          Dismiss{" "}
        </Button>
      </Col>
    </BannerContainer>
  ) : null;
}
