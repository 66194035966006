import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import { Drawer, List } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    '& >.MuiDrawer-paper': {
      padding: '20px',
      background: '#292f39',
      color:'#FFFFFF'
    },
    '& .MuiListItem-button:hover': {
      color: '#fff'
    },
    icon: {
      color: 'red',
    }
  }
}));

export default function Sidebar() {
  const classes = useStyles();
  const [toggle, setToggle] = React.useState(false);
  
  const handleClose = () => {
    setToggle(false)
  } 

  const list = () => (
    
    <div
      role="presentation"
      onKeyDown={() => setToggle(false)}
    >
      <List >
        {SidebarData.map((item, index) => {
          return <SubMenu item={item} key={index} handleCloseEve= {handleClose}/>
        })}
      </List>
      <Divider />
    </div>
  );

  return (
    <>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
        onClick={() => setToggle(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer className={classes.drawer} open={toggle} onClose={() => setToggle(false)} >  
        {list()}
      </Drawer>
    </>
  );
}