import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import App from "../App";
import Admin from "../Admin";
import Home from "../Home";
import Quadratics from "../Quadratics";
import Header from "../Header";
import Footer from "../Footer";
import Banner from "../Compliance";
import Fundamentals from "../Fundamentals";
//import { Security } from "@material-ui/icons";
import AWS_Security from "../AWS_Security";
import privacy from "../Privacy";
import Vpc from "../Vpc";
import EC2 from "../EC2";
import LoadBalancing from "../LoadBalancing";
import Route53 from "../Route53";
import Databases from "../Databases";
import S3 from "../S3";
import Monitoring from "../Monitoring";
import Automation from "../Automation"
import Caching from "../Caching";
import Governance from "../Governance";
import Migration from "../Migration";
import BigData from "../BigData";
import Serverless from "../Serverless";
import ExtraStuff from "../ExtraStuff";

const Routing = (
  <Router>
    <Header />
      <Route exact path="/" component={Home} />
      <Route path="/Admin" component={Admin} />
      {/* <Route path="/App" component={App} /> */}
      {/* <Route path="/Quadratics" component={Quadratics} /> */}
      <Route path="/Fundamentals" component={Fundamentals} />
      <Route path="/Security" component={AWS_Security} />
      <Route path="/VPC" component={Vpc} />
      <Route path="/EC2" component={EC2} />
      <Route path="/LoadBalancing" component={LoadBalancing} />
      <Route path="/Route53" component={Route53} />
      <Route path="/Databases" component={Databases} />
      <Route path="/S3" component={S3} />
      <Route path="/Monitoring" component={Monitoring} />
      <Route path="/Automation" component={Automation} />
      <Route path="/Caching" component={Caching} />
      <Route path="/Governance" component={Governance} />
      <Route path="/Migration" component={Migration} />
      <Route path="/BigData" component={BigData} />
      <Route path="/Serverless" component={Serverless} />
      <Route path="/ExtraStuff" component={ExtraStuff} />
      <Route path="/Privacy" component={privacy} />
    <Footer />
    <Banner />
  </Router>
);

export default Routing;
